import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { MainPicture } from '../../components/MainPicture';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Seo } from '../../components/Seo';
import { colors, device } from '../../components/layout/GlobalStyles';

const StyledDoctors = styled.div`
    padding: 2em 1em;
    .doctor {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
            margin: 1em 0em;
            font-size: 24px;
            color: ${colors.blueDark};
            font-weight: 700;
        }
        .doctor-pic {
            width: 305px;
            height: 371px;
        }
        p {
            margin: 1em 0em;
        }
    }
    @media ${device.tablet} {
        padding-top: 0em;
        .doctor {
            .text {
                width: 500px;
                margin-top: 4em;
            }
            .doctor-pic {
                width: 305px;
                height: 371px;
                margin-top: 6em;
            }
            flex-direction: row;
            max-width: 1100px;
            margin: 0 auto;
            align-items: flex-start;
            justify-content: space-between;
        }
        .rev {
            flex-direction: row-reverse;
        }
    }
    @media ${device.laptopL} {
        .doctor {
            .text {
                width: 730px;
            }
        }
    }
`;

const Doctors: Page = () => (
    <DefaultLayout currentPage="Meet The Doctors">
        <Seo
            title="Meet Magic Smiles Doctors"
            description="Meet our doctors at your local family dentistry in Linden New Jersey"
        />
        <StyledDoctors>
            <MainPicture title="Our Doctors">
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../../images/homepage-team-cropped.jpeg"
                    alt="staff smiling together"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/homepage-team-cropped.jpeg"
                    alt="staff smiling together"
                />
            </MainPicture>
            <div className="rev doctor">
                <StaticImage
                    className="doctor-pic"
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/doctors/bensianoff.jpeg"
                    alt="Dr. Bensianoff"
                />
                <div className="text">
                    <h4>Dr. Anatoly Bensianoff - Practice Owner</h4>
                    <p>
                        Dr. Anatoly Bensianoff earned his Doctor of Dental Surgery Degree from the
                        NYU College of Dentistry in 2001 and completed his residency at Staten
                        Island Hospital in 2001. Drawn to dentistry after volunteering as a
                        resident’s assistant in a dental clinic, Dr. Bensianoff loves to help
                        patients alleviate pain and educate them, giving them the tools they need to
                        maintain a healthy mouth for their lifetime.{' '}
                    </p>
                    <p>
                        Dr. Bensianoff is an active member of the International Congress of
                        Implantologists and takes many advanced continuing education courses each
                        year to provide his patients with the highest quality dentistry available.
                    </p>
                    <p>
                        Dr. Bensianoff enjoys reaching out to the local community and has hosted
                        several free emergency dental treatment events which provides care to
                        underprivileged and uninsured residents of the community.
                    </p>
                </div>
            </div>

            <div className="doctor">
                <StaticImage
                    className="doctor-pic"
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/doctors/dr-yakubov.png"
                    alt="Dr. Yakubov"
                />
                <div className="text">
                    <h4>Dr. Yakov Yakubov - Oral Surgeon</h4>
                    <p>
                        Dr. Yakov Yakubov is a board certified Oral and Maxillofacial Surgeon. He
                        earned his dental and medical degrees at Columbia University, where he was
                        awarded prizes in Oral Surgery and Oral Pathology. Following his dental and
                        medical training, he pursued specialty training in Oral and Maxillofacial
                        Surgery at the NewYork-Presbyterian Hospital, Columbia University Medical
                        Center, where he completed a year of general surgery training. He served as
                        a chief resident in his final year of training. At Columbia, Dr. Yakubov
                        gained training in corrective (orthognathic) jaw surgery, pathology, trauma,
                        and facial cosmetics, as well as complex bone and soft tissue grafting and
                        complex implant reconstruction.
                    </p>
                    <p>
                        Dr. Yakubov has published in the Journal of Oral and Maxillofacial Surgery
                        and the Journal of Oral Surgery, Oral Medicine, Oral Pathology, and Oral
                        Radiology. He is certified in BLS, ACLS, PALS, and ATLS and is a member of
                        the American Association of Oral and Maxillofacial Surgeons.
                    </p>
                    <p>
                        Dr. Yakubov grew up in Queens, NY, and completed his undergraduate studies
                        in neuroscience. He lives in New Jersey with his wonderful wife and two
                        children.
                    </p>
                </div>
            </div>
            <div className="doctor rev">
                <StaticImage
                    className="doctor-pic"
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/doctors/lee.jpeg"
                    alt="Dr. Lee"
                />
                <div className="text">
                    <h4>Dr. Vanessa Lee</h4>
                    <p>
                        Dr. Vanessa Lee attended Rutgers University graduating Cum Laude with a B.A.
                        in Biology. After college, Dr. Lee pursued her interest in the medical
                        sciences earning her Master Degree in Biomedical Sciences, and DMD from The
                        University of Medicine and Dentistry of New Jersey.
                    </p>
                    <p>
                        Dr. Lee completed a year of postgraduate training as a general practice
                        resident at Hackensack University Medical Center. She furthered her training
                        by completing a PGY2 as Chief Resident of the GPR program at Columbia
                        University-New York Presbyterian Hospital.{' '}
                    </p>
                    <p>
                        Dr. Lee joined our practice in 2016 and her desire is to help each of her
                        patients feel happy about their smile. Caring and compassionate, she creates
                        a soothing environment in which anxious patients can relax and get the
                        dental care they need. Outside her passion for dentistry, she enjoys
                        spending time with family and friends, shopping, traveling, and trying new
                        restaurants.
                    </p>
                </div>
            </div>
            <div className="doctor">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <StaticImage
                        className="doctor-pic"
                        imgStyle={{ borderRadius: '20px' }}
                        quality={100}
                        placeholder="none"
                        src="../../images/doctors/dr-lana.jpeg"
                        alt="Dr. Ong"
                    />
                </div>
                <div className="text">
                    <h4>Dr. Sviatlana</h4>
                    <p>
                        Dr. Sviatlana (Dr. Lana) was born and raised in Belarus, small, but
                        beautiful country located in the central part of the Europe. Her first
                        dental degree she received in 2007 from Belarusian State Medical University.
                        She practiced general dentistry at her home country for 4 years, focusing
                        mostly on general dentistry.
                    </p>
                    <p>
                        In the USA she graduated from Virginia Commonwealth University School of
                        Dentistry. Since then she is practicing general dentistry in NJ.
                    </p>
                    <p>
                        Dr. Lana joined our office in 2021, and her main focus is comprehensive
                        approach to a dental care with great and long lasting results. She strives
                        to help patients meet their oral health needs and make them confident with
                        their smiles. As a member of Magic Smile Dental team she shares the same
                        values with the rest of the team: compassion, kindness, empathy and
                        professionalism. In her free time, Dr. Lana enjoys traveling and exploring
                        NY and local restaurants as well as spending time with family and friends.
                    </p>
                </div>
            </div>
            {/* <div className="doctor rev">
                <StaticImage
                    className="doctor-pic"
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/doctors/dr-ong.jpeg"
                    alt="Dr. Ong"
                />
                <div className="text">
                    <h4>Dr. Helen Ong, DDS</h4>

                    <p>
                        Dr. Helen Ong obtained her bachelor's degree at Cornell University and her
                        Doctor of Dental Surgery degree from the State University of New York at
                        Stony Brook. She was recognized for her clinical aptitude and was a
                        recipient of the Academy of General Dentistry award.
                    </p>
                    <p>
                        Dr. Ong pursued postgraduate training in advanced dentistry through a
                        general practice residency at Montefiore Medical Center. She performs all
                        phases of general dentistry for patients of all ages including preventative
                        and restorative care, root canal therapy, dental pain management, and
                        Invisalign. Dr. Ong is committed to life-long learning and stays up to date
                        on the latest dental advancements in order to provide her patients the
                        highest quality of care.
                    </p>
                    <p>
                        Dr. Ong was born and raised in New York City, but now calls Montclair home.
                        When she is not caring for her patients, Dr. Ong enjoys traveling and trying
                        new restaurants.
                    </p>
                </div>
            </div> */}
        </StyledDoctors>
    </DefaultLayout>
);

export default Doctors;
